<template>
  <b-form-group
    label="Firma Adı"
    label-for="company_name"
  >
    <b-form-input
      id="company_name"
      v-model="interviewLine.company_name"
      placeholder="Firma Adı"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'CompanyName',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceRenewals/interviewLine']
    },
  },
}
</script>
