<template>
  <b-form-group
    label="Konu"
    label-for="id_com_interview_subject"
  >
    <validation-provider
      #default="{ errors }"
      name="Konu"
      rules="required"
    >
      <v-select
        id="id_com_interview_subject"
        v-model="interviewLine.id_com_interview_subject"
        :options="subjects"
        :reduce="subject => subject.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewSubject',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceRenewals/interviewLine']
    },
    subjects() {
      return this.$store.getters['interviewSubjects/getInterview_subjects']
    },
  },
  created() {
    localize('tr')
    this.getSubjects()
  },
  methods: {
    getSubjects() {
      this.$store.dispatch('interviewSubjects/interview_subjectsList', {
        select: [
          'com_interview_subject.id AS id',
          'com_interview_subject.title AS title',
        ],
        where: {
          'com_interview_subject.id_com_interview_type': 4,
          'com_interview_subject.insurance_renewal': 1,
        },
        order_by: ['com_interview_subject.id', 'ASC'],
      })
    },
  },
}
</script>

<style scoped>

</style>
