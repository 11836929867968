<template>
  <b-list-group>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Şirket
      </div>
      <div>
        {{ dataLine.sirket }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        TC
      </div>
      <div>
        {{ dataLine.tckn }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Müşteri
      </div>
      <div>
        {{ dataLine.musteri }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Branş
      </div>
      <div>
        {{ dataLine.brans }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Poliçe No
      </div>
      <div>
        {{ dataLine.police_no }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        G. Yıl Prim
      </div>
      <div>
        {{ dataLine.gecen_yil_prim | toCurrency }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Bitiş Tarihi
      </div>
      <div>
        {{ moment(dataLine.bitis_tarihi).format('DD.MM.YYYY') }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'Insurance',
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    dataLine: {
      type: Object,
      required: true,
    },
  },
}
</script>
