<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Yenileme Dönemi"
          label-for="id_com_insurance_renewal_periods"
        >
          <v-select
            id="id_com_insurance_renewal_periods"
            v-model="filterData.id_com_insurance_renewal_periods"
            :options="periodList"
            placeholder="Yenileme Dönemi"
            label="title"
            :reduce="item => item.id"
            @input="getUsers"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Temsilci"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="filterData.id_com_user"
            placeholder="Temsilci"
            :options="userList"
            label="username"
            :reduce="item => item.id"
            :disabled="!filterData.id_com_insurance_renewal_periods"
            @input="getDataList"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Hızlı Arama"
          label-for="search_keyword"
        >
          <b-form-input
            id="search_keyword"
            v-model="filterData.search_keyword"
            placeholder="Müşteri Adı, Poliçe No, Plaka..."
            @keydown.enter="getDataList"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="getDataList"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['insuranceRenewals/dataFilter']
    },
    userList() {
      return this.$store.getters['insuranceRenewals/userList']
    },
    periodList() {
      return this.$store.getters['insuranceRenewalPeriods/dataList']
    },
  },
  methods: {
    getUsers() {
      this.filterData.id_com_user = null
      this.$store.dispatch('insuranceRenewals/getUserList', this.filterData.id_com_insurance_renewal_periods)
      this.getDataList()
    },
    getDataList() {
      this.filterAction()
      // const where = {
      //   'com_insurance_renewal.id_com_insurance_renewal_periods': this.filterData.id_com_insurance_renewal_periods,
      // }
      // if (this.filterData.id_com_user) {
      //   where['com_insurance_renewal.id_com_user'] = this.filterData.id_com_user
      // }
      // if (this.filterData.completeStatus === false) {
      //   where['com_insurance_renewal.id_com_interview'] = null
      // }
      // const orLike = {}
      // if (this.filterData.search_keyword) {
      //   orLike['com_insurance_renewal.musteri'] = this.filterData.search_keyword
      //   orLike['com_insurance_renewal.police_no'] = this.filterData.search_keyword
      // }
      // if (this.filterData.id_com_insurance_renewal_periods) {
      //   this.$store.dispatch('insuranceRenewals/getDataList', {
      //     where,
      //     or_like: orLike,
      //     limit: 50,
      //     start: 0,
      //   })
      // }
    },
  },
}
</script>
