<template>
  <div>
    <b-form-group
      label="Telefon"
      label-for="phone"
    >
      <validation-provider
        #default="{ errors }"
        name="Telefon"
        rules="required"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            TR (+9)
          </b-input-group-prepend>
          <cleave
            id="phone"
            v-model="interviewLine.phone"
            class="form-control"
            :raw="true"
            :options="options.phone"
            placeholder="Telefon"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import { required } from '@validations'

export default {
  name: 'Phone',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      required,
      locale: 'tr',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceRenewals/interviewLine']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>
