<template>
  <div>
    <b-form-group
      label="Müşteri Ara"
      label-for="customer_search"
    >
      <b-input-group>
        <b-form-input
          id="customer_search"
          v-model="search"
          placeholder="Müşteri Ara"
          @keydown.enter="searchData"
        />
        <b-input-group-append>
          <b-button
            variant="primary"
            @click="searchData"
          >
            <FeatherIcon icon="SearchIcon" /> Müşteri Ara
          </b-button>
          <b-button
            variant="warning"
            @click="selectCustomer(null)"
          >
            <FeatherIcon icon="PlusIcon" />
            Yeni Müşteri
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-alert
      v-if="searching === true || searchResult !== null"
      show
    >
      <div class="alert-body">
        <Loading
          v-if="searching"
          class="mt-1"
        />
        <div v-if="searchResult">
          {{ searchResult }}
        </div>
      </div>
    </b-alert>
    <vue-perfect-scrollbar
      class="scroll-area mb-2"
      :settings="settings"
    >
      <b-list-group>
        <b-list-group-item
          v-for="customer in customers"
          :key="customer.id"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="text-primary font-weight-bold">
              {{ customer.name }}
            </div>
            <div
              v-if="customer.company_name"
              class="font-small-2 text-muted"
            >
              {{ customer.company_name }}
            </div>
            <div class="font-small-2 text-warning">
              {{ customer.phone }}
            </div>
          </div>
          <div>
            <b-button
              variant="success"
              size="sm"
              @click="selectCustomer(customer)"
            >
              <FeatherIcon icon="CheckIcon" />
              Müşteri Seç
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BListGroup, BListGroupItem, BButton, BAlert,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'CustomerSearch',
  components: {
    Loading,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    VuePerfectScrollbar,
  },
  data() {
    return {
      searching: false,
      searchResult: null,
      search: null,
      settings: {
        maxScrollbarLength: 60,
      },
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.tckn AS tckn',
          'com_customer.company_name AS company_name',
          'com_customer.name AS name',
          'com_customer.phone AS phone',
          'com_customer.email AS email',
        ],
        order_by: ['id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customers/getCustomers']
    },
    interviewLine() {
      return this.$store.getters['insuranceRenewals/interviewLine']
    },
  },
  methods: {
    selectCustomer(data) {
      this.$store.commit('customers/CUSTOMERS_LIST', [])
      if (data) {
        this.interviewLine.name = data.name
        this.interviewLine.company_name = data.company_name
        this.interviewLine.tckn = data.tckn
        this.interviewLine.phone = data.phone
        this.interviewLine.id_com_customer = data.id
        this.interviewLine.newCustomer = false
      } else {
        this.interviewLine.name = null
        this.interviewLine.company_name = null
        this.interviewLine.tckn = null
        this.interviewLine.phone = null
        this.interviewLine.id_com_customer = null
        this.interviewLine.newCustomer = true
      }
    },
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.tckn': this.search,
        'com_customer.company_name': this.search,
        'com_customer.name': this.search,
        'com_customer.phone': this.search,
      }
      this.getCustomer()
      this.interviewLine.newCustomer = false
    },
    getCustomer() {
      this.searching = true
      this.searchResult = null
      this.$store.dispatch('customers/customersList', this.dataQuery)
        .then(res => {
          this.searching = false
          if (res === 0) {
            this.searchResult = 'Aradığınız müşteri kartı bulunamadı.'
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: 250px;
}
</style>
